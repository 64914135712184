/* You can add global styles to this file, and also import other style files */

@import "../src/app/components/shared/variables.scss";
@import "~bootstrap/dist/css/bootstrap.min.css";

html,
body {
  height: 100%;
  //   background: rgb(82, 93, 114);
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.etiqueraDesarrollo {
  width: 224px;
  height: 29px;
  padding: 0px 35px;
  color: white;
  font-weight: 460;
  background-color: #e45739cb;
  position: fixed;
  font-size: 13px;
  top: 11px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.24), 0 3px 14px 2px rgba(0, 0, 0, 0) !important;
  left: -30px;
  transform: rotate(-30deg);
  z-index: 10000;
}

.efecto {
  animation-name: transparencia;
  animation-duration: 1.2s;
}

@keyframes transparencia {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

//menu
button:focus {
  outline: none !important;
}

.mat-menu-content:not(:empty) {
  padding: 0 !important;
  background-color: $topBar2;
}

.mat-menu-item .mat-icon {
  color: #000;
  font-size: 20px;
}

.mat-menu-item {
  cursor: pointer;
  white-space: nowrap;
  //  line-height: 48px;
  text-align: left;
  color: #000;
  height: 30px;
}

.mat-menu-item:hover:not([disabled]) {
  background-color: rgb(212, 239, 252);
}

.uppercase {
  text-transform: uppercase;
}

///
* {
  outline: none !important;
}

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
}

.iconAli i {
  vertical-align: middle;
  font-weight: 600;
}

// .table-hover tbody tr:hover {
//   color: #212529;
//   background-color: rgba(0, 0, 0, 0.27);
//   cursor: pointer;
// }
//estilos generales abreviados
.puntero {
  cursor: pointer;
}

.fr {
  float: right;
}

.fl {
  float: left;
}

.btn i {
  font-size: 20px;
}

/////////// MATERIAL /////////////////@extend
.mat-form-field {
  width: 100% !important;
}

.mat-form-field:active {
  width: 100% !important;
}

.mat-raised-button {
  padding: 8px 15px !important;
}

table {
  width: 100%;
}

th {
  font-weight: 600;
  text-align: center;
}

////
//mat-calendar
.mat-calendar-body-cell-content {
  font-weight: 600;
}

.mat-calendar-body-disabled
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  font-weight: 400 !important;
}

button.mat-calendar-period-button.mat-button.mat-button-base {
  display: none;
}

#inputMonto {
  .mat-form-field-wrapper {
    width: 95px !important;
  }
}

label.mat-checkbox-layout {
  display: inline-flex !important;
}

/////
#barraComponente {
  height: 60px;
  background-color: $baseBasico;
}

.subir {
  padding: 5px 10px;
  margin: 0;
  cursor: pointer;
  border-radius: 4px;
}

// #footerComponente {
//   background-color: $grisBasico;
//   height: 50px;
// }
//////////////
// table .btn {
//   padding: 0px 4px 4px 4px;
//   margin: 0 4px;
//   vertical-align: middle;
// }
// body .ui-table .ui-table-tbody > tr {
//   background-color: rgba(0, 0, 0, 0.05);
//   color: black;
// }
// body .ui-table .ui-table-thead > tr > th {
//   background-color: #424242;
// }
.ui-panelmenu .ui-menuitem-text:hover {
  color: $activaMenu !important;
}

// .ui-panelmenu .ui-widget{
//   padding-top: 25px !important;
// }
.reporte {
  height: 35px;
  padding: 5px 9px;
  cursor: pointer;
  width: 43px;
  //  position: absolute;
  right: 5px;
  top: 4px;
  border-radius: 5px;
  color: white;
  // background-color: transparent;
}

.conReporte {
  background-color: red;
}

.actualizar {
  width: 100%;
  text-align: right;
}

body .ui-paginator {
  background-color: #424242;
}

body .ui-table .ui-table-tbody > tr:nth-child(even) {
  background-color: white;
}

.seleccionable tr .mat-row:hover {
  background-color: gray;
  cursor: pointer;
}

.mat-raised-button {
  padding: 0 12px !important;
}

.vacio {
  padding-top: 10%;
  width: 100%;
  height: 300px;
  text-align: center;
  color: gray;
}

.cardCustom {
  background-color: white;
  height: auto;
  width: 96%;
  color: $btnAceptar !important;
  // max-width: 500px;
  border-radius: 1px;
  //   box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);
  padding: 10px;
}

.btn-cancelar {
  background-color: transparent;
  color: $btnCancelar;
  margin: 0px 5px !important;
  box-shadow: none !important;
}

.btn-aceptar {
  background-color: transparent;
  color: $btnAceptar;
  margin: 0px 5px !important;
  box-shadow: none !important;
}

.btn-editar {
  background-color: $btnEditar;
  color: white;
  margin: 0px 5px !important;
  box-shadow: none !important;
}

#botonActualizar {
  width: 34px;
  position: absolute;
  right: 25px;
  top: 20px;
}

.btn-eliminar {
  background-color: $btnEliminar;
  color: white;
  margin: 0px 5px !important;
}

.btn-imprimir-etiquetas-numero-contenedor {
  background-color: transparent;
  color: $btnImprEtiquetaNumeroCont;
  margin: 0px 5px !important;
  box-shadow: none !important;
}

.btn-imprimir-etiquetas-ubicacion {
  background-color: transparent;
  color: $btnImprEtiquetaUbicacionCont;
  margin: 0px 5px !important;
  box-shadow: none !important;
}


.rojo {
  color: $rojoVino !important;
  font-weight: 500;
}

.naranja {
  color: $naranja !important;
  font-weight: 500;
}

.gris {
  color: $gris !important;
  font-weight: 500;
}

.azul {
  color: $azul !important;
  font-weight: 500;
}

.verde {
  color: $verde !important;
  font-weight: 500;
}

.verdeSeleccionTasa {
  color: #abfdaf;
}

.naranjaSeleccionTasa {
  color: #ffa87f;
}

//dashboard
//    $rojo: #dd2323;
//    $amarillo: #e7eb18;
//    $azul:  #3b1bf0;
//    $naranja: #e65c32;
//    $gris: #8f8f8f;
// $verde: #388e3c;
// $morado: #9c27b0;
.verdeDash {
  background-color: $verde;
  color: white;
}

.azulDash {
  background-color: $azulDash;
  color: white;
}

.rojoDash {
  background-color: $rojo;
  color: white;
}

.naranjaDash {
  background-color: $naranja;
  color: white;
}

.moradoDash {
  background-color: $morado;
  color: white;
}

.grisTabla {
  // background-color: #e0e0e0;
  color: #1416e9;
  font-weight: 500;
}

.grisDetalleTabla {
  background-color: #f3fbfd;
}

.example-form {
  min-width: 150px;
  max-width: 500px;
  width: 100%;
}

.example-full-width {
  width: 100%;
}

mat-card-header-text {
  margin: 0 !important;
}

// td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type, th.mat-header-cell:first-of-type{
//   background-color: red;
// }
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: black !important;
}


.mat-paginator-outer-container {
  height: 45px !important;
}

.mat-paginator-container {
  //  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.24), 0 3px 14px 2px rgba(0, 0, 0, 0) !important;
  min-height: 30px !important;
  .mat-form-field-wrapper {
    width: 45px !important;
  }
  .mat-paginator-page-size-label {
    width: 230px;
    //   margin: auto;
  }
}

//TABLA MATERIAL
// .tabla{
//   width: 100% !important;
// }
// th {
//   text-align: center !important;
// }
// .tabla{
//   position: relative !important;
//   // max-height: 380px;
//   /* min-width: 503px; */
//   overflow: auto !important;
// }
// tr{
//   .td{
//     width: 200px !important;
//   }
// }
// th.mat-header-cell {
//   color: black !important;
// }
.mat-table {
  overflow-x: scroll;
  font-size: 0.8rem !important;
}

.mat-cell,
.mat-header-cell {
  word-wrap: initial;
  display: table-cell;
  padding: 0px 10px;
  line-break: unset;
  width: auto !important;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
}

.mat-row,
.mat-header-row {
  display: table-row;
}

td.mat-cell,
td.mat-footer-cell,
th.mat-header-cell {
  padding: 0 10px !important;
}

.mat-table {
  thead {
    tr.mat-header-row {
      height: 45px !important;
      //    background-color: #f6f9fe;
    }
    background-color: $baseBasico !important;
    font-size: 0.8rem !important;
    height: 56px !important;
    th {
      color: black !important;
      text-align: center;
      border-bottom: none;
    }
  }
  tbody {
    // background-color: blue !important;
    td {
      font-size: 0.8rem !important;

      .text {
        width: 200px !important;
      }
      .num {
        text-align: center;
        width: 100px !important;
      }
    }
  }
}

.centrar {
  margin-top: 10px;
}

/* Structure */

.example-container {
  position: relative;
  // min-height: 200px;
}

.example-table-container {
  position: relative;
  max-height: 400px;
  overflow: auto;
}

table {
  width: 100%;
}

.example-loading-shade {
  position: absolute;
  top: 45px;
  left: 0;
  bottom: 0px !important;
  right: 0;
  background: rgba(0, 0, 0, 0.19);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.example-rate-limit-reached {
  color: #980000;
  max-width: 360px;
  text-align: center;
}

/* Column Widths */

.mat-column-number,
.mat-column-state {
  max-width: 64px;
}

.mat-column-created {
  max-width: 124px;
}

tr.example-detail-row {
  height: 0 !important;
}

.subTituloTabla {
  color: #0c0c0c;
  font-size: 18px;
  font-weight: 100;
  cursor: initial;
  margin-bottom: 4px;
}

.bloque {
  margin-bottom: 20px;
}

tr.example-element-row:not(.example-expanded-row):hover {
  background: #e8f1f7;
}

tr.example-element-row:not(.example-expanded-row):active {
  background: #efefef;
}

.example-element-row td {
  border-bottom-width: 0;
}

.example-element-detail {
  overflow: hidden;
  display: flex;
  .row {
    width: 100%;
    margin: 10px 0px;
  }
}

// .divTabla{
//   padding: 10px;
// }
// input, .mat-form-field-wrapper .mat-form-field-outline, .mat-input-element,  input.mat-input-element {
//   color: white !important;
// }
// th,
// tr {
//   border: 1px solid #979797 !important;
// }
// th {
//   padding-left: 10px;
//   text-align: center !important;
// }
// .table-bordered th,
// .table-bordered td {
//   border: 1px solid #dee2e6;
// }
.negro {
  color: $negro;
}

// body .ui-inputtext{
//     background-color: rgb(196, 196, 196);
//     color: black;
// }
.enLinea {
  display: inline-flex;
}

.enBloque {
  display: inline-block;
}

.fondoForm {
  background-color: $colorFondoForm;
  border-radius: 5px;
  padding: 20px 10px;
  border: 1px solid #cecece;
  box-shadow: 2px 2px 5px 1px #928f8f;
  // margin: 0;
}

.input-group {
  border-radius: 5px;
  width: 100%;
  box-shadow: 1px 1px 5px 1px #cecece;
}

// .input-group > .form-control {
//   border-top-right-radius: 5px !important;
//   border-bottom-right-radius: 5px !important;
// }
#formulario {
  button.btn.btn-primary,
  .btn-secondary,
  .btn-danger,
  .btn-success {
    padding: 3px 5px 0px 5px;
    margin: 0 4px;
    vertical-align: middle;
  }
}

// .btn-success{
//   padding: 2px 5px !important ;
// }
.radios {
  margin: 0px 0px 15px;
}

.asterisco {
  font-weight: 800;
  color: $baseFuerte;
}

button.ui-datepicker-trigger.ui-calendar-button.ng-tns-c11-2.ui-button.ui-widget.ui-state-default.ui-corner-all.ui-button-icon-only.ng-star-inserted {
  color: #b1a7a7 !important;
  background-color: #333333 !important;
  border: 1px solid #cacaca !important;
}

button.ui-datepicker-trigger.ui-calendar-button.ng-tns-c11-2.ui-button.ui-widget.ui-state-default.ui-corner-all.ui-button-icon-only.ng-star-inserted:hover {
  color: black !important;
  background-color: white !important;
  border: 1px solid rgb(46, 46, 46) !important;
}

svg.mat-datepicker-toggle-default-icon.ng-star-inserted {
  color: blue !important;
  font-size: 35px !important;
}

// input fecha primeNG
input.ng-tns-c11-2.ui-inputtext.ui-widget.ui-state-default.ui-corner-all.ng-star-inserted,
span.ng-tns-c11-2.ui-calendar {
  //   width: 100% !important;
}

body #fecha .ui-inputtext {
  border-radius: 3px !important;
  border: 1px solid #d2d2d2 !important;
  background-color: #ffffff !important;
  color: gray;
}

// simulando mat-expanded
.footer-config {
  background-color: $footerExpansionPanel;
  text-align: right;
  padding: 15px;
  margin-top: 15px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.menu-expanded {
  background-color: $colorFondoForm;
  border-radius: 4px;
  padding: 24px 15px 0 15px;
  box-shadow: 2px 2px 5px 1px #928f8f !important;
  p {
    margin-bottom: 8px;
    font-weight: 300;
  }
  label {
    margin-bottom: 8px;
    font-weight: 500;
  }
  .form-group {
    padding-left: 14px;
    padding-right: 14px;
  }
}

sub {
  bottom: 0.9em !important;
  color: #9b9b9b;
}

// spinner css
.spinner,
.spinner:after {
  border-radius: 50%;
  width: 6em;
  height: 6em;
}

.margin15 {
  margin: 0 15px;
}

.spinner {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.1em solid $colorArchivar !important;
  border-right: 0.2em solid $colorArchivar !important;
  border-bottom: 0.3em solid $colorArchivar!important;
  border-left: 0.4em solid $baseBasico !important;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  // box-shadow: 2px 2px 3px 1px rgb(29, 29, 29);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

.colorAzul {
  border-top: 0.1em solid #cbcbcb !important;
  border-right: 0.2em solid #cbcbcb !important;
  border-bottom: 0.3em solid #cbcbcb !important;
  border-left: 0.4em solid $baseBasico !important;
}

.colorAzul {
  border-top: 0.1em solid #cbcbcb !important;
  border-right: 0.2em solid #cbcbcb !important;
  border-bottom: 0.3em solid #cbcbcb !important;
  border-left: 0.4em solid $baseBasico !important;
}


.colorBlanco {
  border-top: 0.1em solid white !important;
  border-right: 0.2em solid white !important;
  border-bottom: 0.3em solid white !important;
  border-left: 0.2em solid $baseBasico !important;
}

.rotar90 {
  transform: rotate(90deg);
}

.normal {
  transform: none;
}

.mat-snack-bar-top {
  margin-top: 70px !important;
  margin-right: 10px !important;
}

.mat-snack-bar-container {
  min-width: 200px !important;
}

ul {
  padding-left: 1em;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

//color del radio
.mat-checkbox-inner-container {
  margin-left: 0px !important;
  margin-right: 10px !important;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
  overflow: auto;
  pointer-events: auto;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ffb300 !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: rgb(22, 22, 22) !important;
}

.ordenarSpinner {
  position: relative;
}

#btnTablaEliminar {
  cursor: pointer;
  color: #000;
  border-radius: 50px;
  background-color: $btnEliminar;
  width: 30px;
  height: 30px;
  padding: 3px;
}

#btnTablaCancelar {
  cursor: pointer;
  color: #000;
  border-radius: 50px;
  background-color: $btnCancelar;
  width: 30px;
  height: 30px;
  padding: 3px;
}

#btnTablaEditar {
  cursor: pointer;
  cursor: pointer;
  color: #fff;
  border-radius: 50px;
  background-color: $btnEditar;
  width: 30px;
  height: 30px;
  padding: 3px;
}

#btnSearch {
  cursor: pointer;
  cursor: pointer;
  color: #fff;
  border-radius: 25px;
  background-color: $btnEditar;
  min-width: 100px;
  height: 40px;
  padding: 3px;
}

.mat-fab .mat-button-wrapper {
  padding: 0px;
  display: block;
  line-height: 24px;
}

.btnNuevo {
  background-color: #f6f9fe;
  height: 62px;
  border: 1px solid #e4e5e5;
  margin: 0 0 20px 0;
  padding: 8px;
  display: flex;
}

#btnAdd {
  background-color: #689f38 !important;
  position: initial;
  margin: 5px;
  width: auto;
  padding: 5px;
  border-radius: 3px;
  height: auto;
  color: #fff;
  cursor: pointer;
  text-align: center;
}

#btnAdd2 {
  background-color: #4665c9 !important;
  position: initial;
  margin: 5px;
  width: auto;
  padding: 5px;
  border-radius: 3px;
  height: auto;
  color: #fff;
  cursor: pointer;
  text-align: center;
}

#btnActMontos {
  background-color: #357ed1 !important;
  position: initial;
  margin: 5px;
  width: auto;
  padding: 5px;
  border-radius: 3px;
  height: auto;
  color: #fff;
  cursor: pointer;
  text-align: center;
}

#inputCantidad {
  width: 50px !important;
  margin: 0px 2px;
}

#inputMonto {
  width: 90px !important;
  margin: 0px 2px;
}

///////////////
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}

.mat-checkbox-disabled {
  opacity: 0.5 !important;
}

.mat-checkbox-disabled .mat-checkbox-label {
  color: black;
  font-weight: 400;
}

.izquierda {
  text-align: left;
}

.center {
  text-align: center !important;
}

.derecha {
  width: 100%;
  text-align: right !important;
}

.ok {
  background-color: #15a915bf;
  color: white;
}

.error {
  background-color: rgb(187, 51, 51);
  color: white;
}

.divError {
  width: 300px;
  padding: 20px;
  margin: 0 auto;
  height: 100%;
  margin-top: 5%;
  h5 {
    color: gray;
    font-size: 15px !important;
  }
}

tr.mat-row:hover {
  background-color: rgb(216, 216, 216);
  cursor: pointer;
}

.tablaAcciones {
  width: 100%;
  height: auto;
  padding: 10px 15px;
  background-color: #e2e2e2;
  border-radius: 5px;
  display: inline-block;
  margin: 10px 0;
  position: relative;
}

td.acciones {
  text-align: center !important;
}

#lupaBuscar {
  font-size: 18px;
}

button.mat-icon-button.mat-button-base.ng-star-inserted {
  outline: none;
}

.alto {
  margin: 0 auto;
  margin-top: 22px;
  height: 100px;
  width: 100%;
  border: 1px solid #d3d2d2;
  background-color: white;
  border-radius: 5px;
  text-align: center;
  padding-top: 35px;
  box-shadow: 2px 2px 5px 1px #928f8f;
}

#iconConfig {
}

.opacidadAlta {
  opacity: 1;
  font-weight: 500;
}

.opacidadBaja {
  opacity: 0.5;
}

.buscar i {
  vertical-align: middle;
  font-size: 24px;
}

.buscar {
  // position: absolute;
  // right: 28px;
  // top: 7px;
  display: inline-flex;
  margin: 0 10px;
  padding: 7px 0px 0px 3px;
}

.filtros {
  display: inline-block;
  // background-color: red;
  // margin-top: 15px;
}

.cabecera {
  // background-color: green;
  display: inline-flex;
  width: 100%;
}

.mat-dialog-container {
}

.claseDialogo {
  // background-color: red !important;
  // width: 98% !important;
  // max-width: 98% !important;
  // height: 100% !important;
}

//fondos
.fondoGris {
  background-color: $hojaBlanca;
}

.warning {
  background-color: $warning;
}

//mat-spinner
#spin {
  margin: 0 auto !important;
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

circle.ng-star-inserted {
  stroke-width: 4% !important;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #929292 !important;
}

// estilos material-plegable
.sacarMargin {
  margin: 1px 0px !important;
}

.mat-expansion-panel-header {
  background-color: $colorFondoForm;
  height: 55px !important;
  margin-bottom: 8px !important;
}

.mat-expansion-panel {
  color: rgb(102, 102, 102);
  .enBloque {
    margin-top: 17px;
  }
  .mat-icon {
    margin-top: 15px;
  }
}

.mat-expansion-panel-body {
  padding: 0 20px 16px;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: blue !important;
}

.mat-expansion-panel-body,
.fondoForm {
  p {
    margin-bottom: 0;
    font-weight: 500;
  }
  label {
    font-weight: 300;
    font-size: 0.8em;
    .anchoRadioSexo {
      margin-top: 5px;
      width: 68px;
    }
    .anchoRadio {
      width: 150px;
    }
  }
}

.mat-expansion-indicator {
  margin-top: -15px !important;
}

///////////////////////
.btn {
  font-size: 0.8rem;
}

.mat-expansion-panel-spacing {
  background-color: $colorFondoForm;
}

.mat-action-row {
  background-color: $footerExpansionPanel;
  padding: 10px 8px 10px 26px !important;
  border-top-color: none !important;
}

.mat-expansion-panel,
.mat-expansion-panel-spacing {
  box-shadow: 2px 2px 5px 1px #928f8f !important;
}

.btn i {
  vertical-align: middle !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: blue;
}

.iconReporte {
  vertical-align: middle;
  margin-top: 4px;
}

.accion {
  margin-top: 13px;
}

.advertencia {
  padding: 10px 20px;
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
  // display: inline-flex;
}

.tabla-tasas {
  overflow: overlay !important;
  th.mat-header-cell,
  td.mat-cell,
  td.mat-footer-cell {
    border-bottom-color: #dadada !important;
  }
  label {
    display: block;
    color: #000000;
    font-weight: 500;
    white-space: normal;
    strong {
      font-weight: 500;
      color: #0a0a0a87;
    }
  }
}

@media (max-width: 768px) {
  .tabla-tasas {
    overflow-x: scroll !important;
  }
  .mat-button,
  .mat-icon-button {
    &.cdk-focused,
    &.cdk-program-focused {
      background-color: none !important;
      .mat-button-focus-overlay {
        display: contents !important;
      }
    }
  }
  // .claseDialogo {
  //   // background-color: red !important;
  //   width: 98% !important;
  //   max-width: 98% !important;
  //   height: 100% !important;
  // }
  h5 {
    font-size: 1rem;
  }
  .letraFooter {
    font-size: 0.6em;
  }
  .btn {
    font-size: 12px !important;
  }
  .nuevo .mat-expansion-panel-body,
  .fondoForm {
    label {
      // width: 58% ;
    }
    // .labelGrande{
    //   width: 70% !important
    // }
    // .labelChico{
    //   width: 30% !important
    // }
  }
  #layoutExterno .contenedor {
    padding: 20px 5px 25px;
    p {
      font-size: 0.9em;
    }
  }
  .btn-cancelar {
    font-size: 0.8em;
  }
  .btn-aceptar {
    font-size: 0.8em;
    margin-bottom: 20px !important;
  }
  //TABLA RESPONSIVA
  .mat-table thead tr.mat-header-row {
    height: 35px !important;
  }
  //  tr.mat-footer-row, tr.mat-row {
  //   height: 35px !important;
  // }
  //paginador
  .mat-paginator-container {
    height: 40px;
    .mat-paginator-page-size-label {
      width: 220px !important;
    }
  }
  .mat-paginator-range-actions {
    position: absolute !important;
    .mat-icon-button {
      width: 25px !important;
    }
  }
  .mat-paginator-outer-container {
    display: flex;
    height: 40px !important;
  }
  .mat-paginator-page-size {
    display: flex;
    align-items: baseline;
    position: absolute;
    left: 30px;
  }
  .accion {
    margin-top: 9px;
  }
  .iconReporte {
    margin-top: 0px;
  }
}

// ESTILOS DEL SPINNER INICIAL
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* ESTILOS IMPUTS */

.inputtext {
  padding: 0.4375em 0;
  //  border-bottom-style: none !important;
  //    border: 1px solid #D7D8D9 !important;
}

.inputtext:hover {
  padding: 0.4375em 0;
  //    border: 1px solid rgb(158, 158, 255);
  //transition: 500ms;
}

//Standard
.mat-form-field {
  .mat-input-element {
    color: #000;
  //  margin-left: 15px;
    margin: auto;
  }
  .mat-form-field-label {
    color: slategray;
  }
  .mat-form-field-underline {
    background-color: $colorArchivar;
    border: none;
  }
  .mat-form-field-underline:hover {
    background-color: $colorArchivar;
    border: none;
  }
  .mat-form-field-ripple {
    //      background-color: rgb(98, 190, 11) !important;
    border: 1px solid blueviolet;
  }
  .mat-form-field-required-marker {
    color: $colorArchivar;
  }
}

//Focus
.mat-form-field.mat-focused {
  .mat-form-field-label {
    color: $colorArchivar;
  }
  .mat-form-field-ripple {
    background-color: $colorArchivar;
  }
  .mat-form-field-required-marker {
    color: $colorArchivar;
  }
  .mat-input-element {
    color: $colorArchivar;
  }
}

//Error
.mat-form-field.mat-form-field-invalid {
  .mat-input-element {
    color: #aa1a1a;
  }
  .mat-form-field-label {
    color: #aa1a1a;
    .mat-form-field-required-marker {
      color: #aa1a1a;
    }
  }
  .mat-form-field-ripple {
    background-color: #aa1a1a;
  }
}

// Layout para Tablas
.table-label-etiqueta {
  font-size: large;
  border-bottom: grey;
  border-bottom-style: solid;
  border-bottom-width: thin;
  margin-top: 25px;
}

