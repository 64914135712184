/* Color principal */
$colorArchivar: #005eb8;

/* Colores login */

$background-1: #525d72;
$background-2: #68758c;

/*  Colores base  */

$topBar1: #005eb8;
$topBar2: #fff;
$baseBasico: #f6f9fe;
$baseFuerte:#fff;
$baseClaro: #fff;
$colorTextos: rgb(22, 22, 22);
$grisBasico: #202020;
$grisFuerte: #555555;
$grisClaro: #ffffff;
$negro: rgba(22, 22, 22, 0.712);
$rojoVino: #b71c1c;
$warning: #ffc107;
$gris: #a09b8c;
$colorFondoForm: white !important;
$footerExpansionPanel: $baseBasico;
$hojaBlanca: #ecf3fa;

/* template: menu y toolbar */

$backgroundMenu-1: #135aa9;
$backgroundMenu-2: #1a73c4;

$altoToolbar: 48px !important;
$anchoMenuLateral: 270px !important;
$activaMenu:$baseFuerte;
$fondoDrawer: white;
$fondoMenu: #3c4962;

/* COLORES BOTONES*/

$btnNuevo: #689f38;
$btnEditar: #508620;
$btnEliminar: #fbc02d;
$btnAceptar: $colorArchivar;
$btnNuevoOtro: $colorArchivar;
$btnCancelar: #e17f7f;
$btnImprEtiquetaNumeroCont: #2d50b3;
$btnImprEtiquetaUbicacionCont: #12709c;



/* dashboard */

$rojo: #dd2323;
$amarillo: #e7eb18;
$azul: #3b1bf0;
$azulDash: #3b1bf0cf;
$naranja: #e65c32;
$gris: #8f8f8f;
$verde: #388e3c;
$morado: #9c27b0;
